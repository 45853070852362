.heading-2xl {
	font-size: 48px;
	line-height: 56px;
	font-weight: 500;
	@media (--tablet) {
		font-size: 56px;
		line-height: 64px;
	}
	@media (--desktop) {
		font-size: 96px;
		line-height: 104px;
	}
	@media (--big-screen) {
		font-size: 118px;
		line-height: 126px;
	}
}

.heading-xl {
	font-size: 34px;
	line-height: 42px;
	font-weight: 500;
	@media (--tablet) {
		font-size: 48px;
		line-height: 56px;
	}
	@media (--desktop) {
		font-size: 56px;
		line-height: 64px;
	}
	@media (--big-screen) {
		font-size: 72px;
		line-height: 80px;
	}
}

.heading-lg {
	font-size: 24px;
	line-height: 32px;
	font-weight: 400;
	@media (--tablet) {
		font-size: 32px;
		line-height: 48px;
	}
	@media (--desktop) {
		font-size: 48px;
		line-height: 56px;
	}
	@media (--big-screen) {
		font-size: 64px;
		line-height: 72px;
	}
}

.heading-md {
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;
	@media (--tablet) {
		font-size: 24px;
		line-height: 32px;
	}
	@media (--desktop) {
		font-size: 32px;
		line-height: 44px;
	}
	@media (--big-screen) {
		font-size: 40px;
		line-height: 48px;
	}
}

.heading-sm {
	font-size: 18px;
	line-height: 26px;
	font-weight: 500;
	@media (--tablet) {
		font-size: 20px;
		line-height: 28px;
	}
	@media (--desktop) {
		font-size: 24px;
		line-height: 32px;
	}
	@media (--big-screen) {
		font-size: 32px;
		line-height: 44px;
	}
}

.heading-xs {
	font-size: 16px;
	line-height: 22px;
	font-weight: 400;
	@media (--tablet) {
		font-size: 18px;
		line-height: 22px;
	}
	@media (--desktop) {
		font-size: 18px;
		line-height: 26px;
	}
	@media (--big-screen) {
		font-size: 24px;
		line-height: 32px;
	}
}

.body-lead {
	font-size: 20px;
	line-height: 26px;
	font-weight: 400;
	@media (--tablet) {
		font-size: 20px;
		line-height: 28px;
	}
	@media (--desktop) {
		font-size: 24px;
		line-height: 32px;
	}
	@media (--big-screen) {
		font-size: 24px;
		line-height: 32px;
	}
}

.body-lead-md {
	font-size: 20px;
	line-height: 26px;
	font-weight: 400;
	@media (--tablet) {
		font-size: 20px;
		line-height: 28px;
	}
	@media (--desktop) {
		font-size: 24px;
		line-height: 32px;
	}
	@media (--big-screen) {
		font-size: 24px;
		line-height: 32px;
	}
}

.body-default {
	font-size: 16px;
	line-height: 22px;
	font-weight: 300;
	@media (--tablet) {
		font-size: 17px;
		line-height: 22px;
	}
	@media (--desktop) {
		font-size: 18px;
		line-height: 26px;
	}
	@media (--big-screen) {
		font-size: 18px;
		line-height: 24px;
	}
}

.body-default-md {
	font-size: 16px;
	line-height: 22px;
	font-weight: 500;
	@media (--tablet) {
		font-size: 17px;
		line-height: 22px;
	}
	@media (--desktop) {
		font-size: 18px;
		line-height: 26px;
	}
	@media (--big-screen) {
		font-size: 18px;
		line-height: 24px;
	}
}

.body-inline-md {
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	@media (--tablet) {
		font-size: 14px;
		line-height: 18px;
	}
	@media (--desktop) {
		font-size: 16px;
		line-height: 22px;
	}
	@media (--big-screen) {
		font-size: 16px;
		line-height: 22px;
	}
}

.body-inline-md-bold {
	font-size: 14px;
	line-height: 18px;
	font-weight: 700;
	@media (--tablet) {
		font-size: 14px;
		line-height: 18px;
	}
	@media (--desktop) {
		font-size: 16px;
		line-height: 22px;
	}
	@media (--big-screen) {
		font-size: 16px;
		line-height: 22px;
	}
}

.body-inline-sm {
	font-size: 13px;
	line-height: 16px;
	font-weight: 300;
	@media (--tablet) {
		font-size: 13px;
		line-height: 18px;
	}
	@media (--desktop) {
		font-size: 14px;
		line-height: 18px;
	}
	@media (--big-screen) {
		font-size: 14px;
		line-height: 18px;
	}
}

.body-inline-sm-bold {
	font-size: 13px;
	line-height: 16px;
	font-weight: 700;
	@media (--tablet) {
		font-size: 13px;
		line-height: 18px;
	}
	@media (--desktop) {
		font-weight: 300;
		font-size: 14px;
		line-height: 18px;
	}
	@media (--big-screen) {
		font-size: 14px;
		line-height: 18px;
	}
}

/* Components */
.quote {
	/* font-family: var(--font-2); */
	font-size: 24px;
	line-height: 32px;
	font-weight: 400;
	@media (--tablet) {
		font-size: 32px;
		line-height: 40px;
	}
	@media (--desktop) {
		font-size: 48px;
		line-height: 56px;
	}
	@media (--big-screen) {
		font-size: 64px;
		line-height: 72px;
	}
}

.menu {
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;
	@media (--tablet) {
		font-size: 24px;
		line-height: 32px;
	}
	@media (--desktop) {
		font-size: 16px;
		line-height: 24px;
	}
	@media (--big-screen) {
		font-size: 18px;
		line-height: 26px;
	}
}

.label-button {
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
}

/* Estilo de link para UI (No wysiwyg) */
/* .ui-link {
	color: var(--text-link-1);

	&:hover {
		color: var(--text-link-1-hover);
	}

	&:active {
		color: var(--text-link-1-active);
	}

	& a:focus-visible {
		color: var(--text-link-1-focus);
		outline: 2px solid var(--focus-link-1);
		border-radius: var(--radii-xs);
	}
} */
