/* global scope styles */
:root {
	font-family: var(--font-1);
}

[data-theme="marketing-theme"] {
	font-family: var(--font-1);
}

.debug {
	border: 4px dashed #ff0000;
}

#___griddo,
#root,
html,
body {
	height: 100%;
	background: none !important;
}

/* ========================================================================== */
/* GLOBALES PARA REACT SLICK CAROUSEL                                         */
/* ========================================================================== */

.slick-track {
	display: flex !important;
	align-items: center !important;
}

/* useBodyLock */
& .body-lock {
	overflow: hidden;
}

/* ==================== */
/* Globales por Módulos */
/* ==================== */

/* Contenedor de los "dots" de navegación */
[data-module="HorizontalScrollCardCollection"] .swiper-pagination,
[data-module="HorizontalScrollCardCollection"] .swiper-custom-pagination,
[data-module="TeamDistributor"] .swiper-custom-pagination,
[data-module="CaseDistributor"] .swiper-pagination {
	display: flex;
	justify-content: center;
	padding: var(--spc-xs);
	column-gap: var(--spc-2xs);
	padding-top: var(--spc-lg);
}

[data-module="CardCollectionSlider"] .card-collection-slider-swiper-custom-pagination {
	display: flex;
	padding: var(--spc-xs);
	justify-content: center;
	column-gap: var(--spc-2xs);

	@media (--tablet) {
		flex-direction: column;
		justify-content: center;
		row-gap: var(--spc-2xs);
	}
}

/* ====== */
/* Froala */
/* ====== */

/* IMAGE INLINE */

.fr-fic.fr-dii {
	width: 100% !important;
}

.fr-fic.fr-dii.fr-fil {
	float: left;
	width: 50% !important;
	padding-right: 32px;
}

.fr-fic.fr-dii.fr-fir {
	width: 50% !important;
	float: right;
	padding-left: 32px;
}

/* IMAGE BREAK TEXT */
.fr-fic.fr-dib {
	display: block;
	width: 100% !important;
	margin-bottom: 32px;
}

.fr-fic.fr-dib.fr-fil {
	width: 50% !important;
}

.fr-fic.fr-dib.fr-fir {
	width: 50% !important;
	margin-left: 50%;
}
