/*

  Theme: Marketing theme

  -----------------------------------
  🚫 DO NOT EDIT THIS FILE DIRECTLY 🚫
  -----------------------------------

  To generate this file:
  1 - Edit `src/themes/marketing-theme` files
  2 - Run `yarn build:themes`

*/

[data-theme="marketing-theme"] {
	/* fontFamily */
	--font-1: Gilroy, Arial, Helvetica, sans-serif;
	/* brandColors */
	--brand-1: #101010;
	--brand-2: #ffffff;
	--brand-3: #fa0067;
}
[data-theme="marketing-theme"][data-subtheme="inverse"],
[data-theme="marketing-theme"] [data-subtheme="inverse"] {
	/* overlays */
	--overlay-1: #101010;
	--overlay-2: linear-gradient(
		180deg,
		rgba(16, 16, 16, 0.08) 0%,
		rgba(16, 16, 16, 0) 100%
	);
	--overlay-3: rgba(16, 16, 16, 0.96);
	/* support */
	--success: #84c36f;
	--error: #ee4444;
	--focus: #ff99c3;
	--focus-line: #9cacfc;
	/* shadowColor */
	--shadow-1: 255, 255, 255;
	/* boxShadow */
	--box-shadow-sm: 0px 4px 16px rgba(var(--shadow-1), 0.15);
	--box-shadow-md: 0px 5px 26px rgba(var(--shadow-1), 0.15);
	--box-shadow-lg: 0px 20px 34px rgba(var(--shadow-1), 0.15);
	--box-shadow-xl: 0px 19px 26px 8px rgba(var(--shadow-1), 0.15);
	/* menuInteraction */
	--menu-interaction-1: transparent;
	--menu-interaction-1-hover: #fa0067;
	--menu-interaction-1-active: #ff4c96;
	--menu-interaction-1-focus: transparent;
	/* inverse */
	--inverse-1: #101010;
	--inverse-2: #ffffff;
	/* graphics */
	--graphics-1: #e6e6e6;
	--graphics-2: #fa0067;
	--graphics-3: #ff61a2;
	--graphics-4: #ffc7de;
	--graphics-5: #ff2e84;
	--graphics-6: #c70052;
	--graphics-7: #ad0047;
	--graphics-8: rgba(250, 0, 103, 0.3);
	/* text */
	--text-1: #ffffff;
	--text-2: #d3d3d3;
	--text-3: #f7f7f7;
	--text-4: #fa0067;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #ffffff;
	--icon-2: #fa0067;
	--icon-3: #f6f6f6;
	--icon-disabled: #999999;
	/* backgroundButton */
	--bg-button-1: #ffffff;
	--bg-button-1-hover: #fa0067;
	--bg-button-1-active: #ff61a2;
	--bg-button-1-focus: #ffffff;
	--bg-button-1-disabled: #e6e6e6;
	--bg-button-2: transparent;
	--bg-button-2-hover: transparent;
	--bg-button-2-active: transparent;
	--bg-button-2-focus: transparent;
	--bg-button-2-disabled: transparent;
	--bg-button-3: transparent;
	--bg-button-3-hover: transparent;
	--bg-button-3-active: transparent;
	--bg-button-3-focus: transparent;
	--bg-button-3-disabled: transparent;
	--bg-button-4: #fa0067;
	--bg-button-4-hover: #ff4c96;
	--bg-button-4-active: #ff4c96;
	--bg-button-4-focus: #fa0067;
	--bg-button-4-disabled: #e6e6e6;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: transparent;
	--border-button-1-disabled: transparent;
	--border-button-2: #ffffff;
	--border-button-2-hover: #fa0067;
	--border-button-2-active: #ff4c96;
	--border-button-2-focus: #ffffff;
	--border-button-2-disabled: #e6e6e6;
	--border-button-3: transparent;
	--border-button-3-hover: transparent;
	--border-button-3-active: transparent;
	--border-button-3-focus: transparent;
	--border-button-3-disabled: transparent;
	--border-button-4: transparent;
	--border-button-4-hover: transparent;
	--border-button-4-active: transparent;
	--border-button-4-focus: transparent;
	--border-button-4-disabled: transparent;
	/* textButton */
	--label-button-1: #101010;
	--label-button-1-hover: #ffffff;
	--label-button-1-active: #ffffff;
	--label-button-1-focus: #101010;
	--label-button-1-disabled: #999999;
	--label-button-2: #ffffff;
	--label-button-2-hover: #fa0067;
	--label-button-2-active: #ff4c96;
	--label-button-2-focus: #ffffff;
	--label-button-2-disabled: #999999;
	--label-button-3: #ffffff;
	--label-button-3-hover: #fa0067;
	--label-button-3-active: #ff4c96;
	--label-button-3-focus: #ffffff;
	--label-button-3-disabled: #999999;
	--label-button-4: #ffffff;
	--label-button-4-hover: #ffffff;
	--label-button-4-active: #ffffff;
	--label-button-4-focus: #ffffff;
	--label-button-4-disabled: #999999;
	/* textLink */
	--text-link-1: #fa0067;
	--text-link-1-hover: #ff4c96;
	--text-link-1-active: #fa0067;
	--text-link-1-focus: #fa0067;
	--text-link-1-disabled: #999999;
	--text-link-2: #ffffff;
	--text-link-2-hover: #fa0067;
	--text-link-2-active: #ff4c96;
	--text-link-2-focus: #ffffff;
	--text-link-2-disabled: #999999;
	--menu-link-1: #ffffff;
	--menu-link-1-hover: #ffffff;
	--menu-link-1-active: #ffffff;
	--menu-link-1-focus: #ffffff;
	--menu-link-1-disabled: #999999;
	--menu-link-2: #ffffff;
	--menu-link-2-hover: #fa0067;
	--menu-link-2-active: #ff61a2;
	--menu-link-2-focus: #ffffff;
	--menu-link-2-disabled: #999999;
	/* border */
	--border-1: #ffffff;
	--border-2: #101010;
	--border-3: #fa0067;
	--border-disabled: #e6e6e6;
	/* bg */
	--bg-ui: #101010;
	--bg-1: #101010;
	--bg-2: #101010;
	--bg-3: #fa0067;
	--bg-4: #333333;
	--bg-5: #787878;
	--bg-disabled: #c8c8c8;
}
[data-theme="marketing-theme"][data-subtheme="default"],
[data-theme="marketing-theme"] [data-subtheme="default"] {
	/* overlays */
	--overlay-1: #ffffff;
	--overlay-2: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.08) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	--overlay-3: rgba(255, 255, 255, 0.96);
	/* support */
	--success: #84c36f;
	--error: #ee4444;
	--focus: #cc0054;
	--focus-line: #516dfb;
	/* shadowColor */
	--shadow-1: 16, 16, 16;
	/* boxShadow */
	--box-shadow-sm: 0px 4px 18px rgba(var(--shadow-1), 0.08);
	--box-shadow-md: 0px 5px 26px rgba(var(--shadow-1), 0.12);
	--box-shadow-lg: 0px 20px 34px rgba(var(--shadow-1), 0.1);
	--box-shadow-xl: 0px 18px 26px rgba(var(--shadow-1), 0.2);
	/* menuInteraction */
	--menu-interaction-1: transparent;
	--menu-interaction-1-hover: #fa0067;
	--menu-interaction-1-active: #ff4c96;
	--menu-interaction-1-focus: transparent;
	/* inverse */
	--inverse-1: #ffffff;
	--inverse-2: #101010;
	/* graphics */
	--graphics-1: #e6e6e6;
	--graphics-2: #fa0067;
	--graphics-3: #ff61a2;
	--graphics-4: #ffc7de;
	--graphics-5: #ff2e84;
	--graphics-6: #c70052;
	--graphics-7: #ad0047;
	--graphics-8: rgba(250, 0, 103, 0.3);
	/* text */
	--text-1: #101010;
	--text-2: #363636;
	--text-3: #787878;
	--text-4: #fa0067;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #101010;
	--icon-2: #fa0067;
	--icon-3: #f7f7f7;
	--icon-disabled: #999999;
	/* backgroundButton */
	--bg-button-1: #101010;
	--bg-button-1-hover: #fa0067;
	--bg-button-1-active: #ff61a2;
	--bg-button-1-focus: #fa0067;
	--bg-button-1-disabled: #e6e6e6;
	--bg-button-2: transparent;
	--bg-button-2-hover: transparent;
	--bg-button-2-active: transparent;
	--bg-button-2-focus: transparent;
	--bg-button-2-disabled: transparent;
	--bg-button-3: transparent;
	--bg-button-3-hover: transparent;
	--bg-button-3-active: transparent;
	--bg-button-3-focus: transparent;
	--bg-button-3-disabled: transparent;
	--bg-button-4: #fa0067;
	--bg-button-4-hover: #ff4c96;
	--bg-button-4-active: #ff4c96;
	--bg-button-4-focus: #fa0067;
	--bg-button-4-disabled: #e6e6e6;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: transparent;
	--border-button-1-disabled: transparent;
	--border-button-2: #101010;
	--border-button-2-hover: #fa0067;
	--border-button-2-active: #ff4c96;
	--border-button-2-focus: #101010;
	--border-button-2-disabled: #e6e6e6;
	--border-button-3: transparent;
	--border-button-3-hover: transparent;
	--border-button-3-active: transparent;
	--border-button-3-focus: transparent;
	--border-button-3-disabled: transparent;
	--border-button-4: transparent;
	--border-button-4-hover: transparent;
	--border-button-4-active: transparent;
	--border-button-4-focus: transparent;
	--border-button-4-disabled: transparent;
	/* textButton */
	--label-button-1: #ffffff;
	--label-button-1-hover: #ffffff;
	--label-button-1-active: #ffffff;
	--label-button-1-focus: #ffffff;
	--label-button-1-disabled: #999999;
	--label-button-2: #101010;
	--label-button-2-hover: #fa0067;
	--label-button-2-active: #ff4c96;
	--label-button-2-focus: #101010;
	--label-button-2-disabled: #999999;
	--label-button-3: #101010;
	--label-button-3-hover: #fa0067;
	--label-button-3-active: #ff4c96;
	--label-button-3-focus: #fa0067;
	--label-button-3-disabled: #999999;
	--label-button-4: #ffffff;
	--label-button-4-hover: #ffffff;
	--label-button-4-active: #ffffff;
	--label-button-4-focus: #ffffff;
	--label-button-4-disabled: #999999;
	/* textLink */
	--text-link-1: #fa0067;
	--text-link-1-hover: #ff4c96;
	--text-link-1-active: #fa0067;
	--text-link-1-focus: #fa0067;
	--text-link-1-disabled: #999999;
	--text-link-2: #101010;
	--text-link-2-hover: #fa0067;
	--text-link-2-active: #ff4c96;
	--text-link-2-focus: #101010;
	--text-link-2-disabled: #999999;
	--menu-link-1: #101010;
	--menu-link-1-hover: #101010;
	--menu-link-1-active: #101010;
	--menu-link-1-focus: #101010;
	--menu-link-1-disabled: #999999;
	--menu-link-2: #101010;
	--menu-link-2-hover: #fa0067;
	--menu-link-2-active: #ff61a2;
	--menu-link-2-focus: #101010;
	--menu-link-2-disabled: #999999;
	/* border */
	--border-1: #101010;
	--border-2: #ffffff;
	--border-3: #fa0067;
	--border-disabled: #e6e6e6;
	/* bg */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-2: #fafafa;
	--bg-3: #fa0067;
	--bg-4: #ffffff;
	--bg-5: ##f3f3f3;
	--bg-disabled: #e6e6e6;
}
