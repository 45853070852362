/*

  Theme: Global theme

  -----------------------------------
  🚫 DO NOT EDIT THIS FILE DIRECTLY 🚫
  -----------------------------------

  To generate this file:
  1 - Edit `src/themes/global-theme` files
  2 - Run `yarn build:themes`

*/

@custom-media --tablet (min-width: 700px);
@custom-media --desktop (min-width: 1025px);
@custom-media --big-screen (min-width: 1681px);

:root {
	/* fontFamily */
	--font-1: Gilroy, Arial, Helvetica, sans-serif;
	/* containers */
	--mobile-max-width: 100%;
	--tablet-max-width: 100%;
	--desktop-max-width: 100%;
	--big-screen-max-width: 1760px;
	/* radii */
	--radii-sm: 4px;
	--radii-md: 8px;
	--radii-lg: 24px;
	--radii-full: 9999px;
	/* spacing */
	--spc-4xs: 4px;
	--spc-3xs: 8px;
	--spc-2xs: 16px;
	--spc-xs: 24px;
	--spc-sm: 32px;
	--spc-md: 40px;
	--spc-lg: 48px;
	--spc-xl: 56px;
	--spc-2xl: 64px;
	--spc-3xl: 80px;
	--spc-4xl: 96px;
	--spc-5xl: 128px;
	--spc-6xl: 160px;
	/* opacity */
	--opacity-1: 0.08;
	--opacity-2: 0.3;
	--opacity-3: 0.4;
	--opacity-4: 0.56;
	--opacity-5: 0.64;
	--opacity-6: 0.72;
	--opacity-7: 0.96;
	/* sizes */
	--size-2xs: 8px;
	--size-xs: 16px;
	--size-sm: 24px;
	--size-md: 32px;
	--size-lg: 48px;
	--size-xl: 56px;
	--size-2xl: 64px;
	/* borderWidth */
	--border-width-none: 0px;
	--border-width-xs: 1px;
	--border-width-sm: 2px;
	--border-width-md: 4px;
	--border-width-lg: 8px;
	/* minHeigh */
	--min-height-hero-1: 400px;
	--min-height-hero-2: 600px;
	--min-height-hero-3: 800px;
	--min-height-hero-4: 1000px;
	/* animations */
	--fade-in: fadein both 1.5s cubic-bezier(0.61, 0.43, 0.15, 1);
	/* timing */
	--duration-xs: 0.1s;
	--duration-sm: 0.2s;
	--duration-md: 0.3s;
	--duration-lg: 0.5s;
	--duration-xl: 0.75s;
	/* easing */
	--easing-quad: var(--ease-in-out-quad);
	--easing-cubic: var(--ease-in-out-cubic);
	--easing-quart: var(--ease-in-out-quart);
	--easing-quint: var(--ease-in-out-quint);
	--easing-expo: var(--ease-in-out-expo);
	/* easingFunctions */
	--ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	--ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
	--ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
	--ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
	--ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
}
